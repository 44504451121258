<!--
	This is a component that eases use of a  layout that we seem to use a lot.
	We have 3 sections: a title, an image, and a body.
	In desktop mode, the image floats as a separate column next to the text content:
	+---------+-------+
	|         | Title |
	|         |-------|
	|  Image  |       |
	|         | Body  |
	|         |       |
	+---------+-------+
	In mobile, the image comes between the title and body:
	+---------+
	|  Title  |
	|---------|
	|         |
	|         |
	|  Image  |
	|         |
	|         |
	|---------|
	|         |
	|  Body   |
	|         |
	+---------+

	This can be done in pure css without duplicating content, but the
	css is a little tricky. You can either use this CSS as an example or
	Use this component directly via slots:
	
	<ThreeFold>
		<div slot="title">
			<h1>It's Great</h1>
		</div>
		<div slot="image">
			<img style="width: 100%; max-width: 500px;" src="phone.png" />
		</div>
		<div slot="body">
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit.
			</p>
			<button>Buy Now!</button>
		</div>
	</ThreeFold>

	Pass `reverse={true}` to put the image on the right side instead
	of the left.
	At full size, the columns are each assigned 50% of the width.
	If the image content is scalable (e.g. <img> has "width: 100%"),
	the component will favor the title and body over the image through
	the transitional area.
-->
<script>
  export let reverse = false
</script>

<style>
  /* mobile style */
  .three-fold {
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-template-rows: auto 1fr;
  }

  /* desktop style overrides */
  @media only screen and (width >= 1000px),
    screen and (orientation: landscape) {
    .three-fold {
      display: grid;
      grid-template-columns: minmax(auto, 1fr) 1fr;
      grid-template-rows: auto 1fr;
    }

    /* normal mode */
    .title {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }

    .image {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }

    .body {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    /* reverse mode */
    .three-fold.reverse {
      /* swap weights */
      grid-template-columns: 1fr minmax(auto, 1fr);
      grid-template-rows: auto 1fr;
    }

    .three-fold.reverse .title,
    .three-fold.reverse .body {
      grid-column: 1 / 2;
    }

    .three-fold.reverse .image {
      grid-column: 2 / 3;
    }
  }
</style>

<div class="three-fold" class:reverse>
  <div class="title">
    <slot name="title" />
  </div>
  <div class="image">
    <slot name="image" />
  </div>
  <div class="body">
    <slot name="body" />
  </div>
</div>
